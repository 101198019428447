import ScrollReveal from 'scrollreveal'

export function navbarFixedTopAnimation() {
  var scroll_pos = 0;
  $(".navbar-default").removeClass('active').addClass('navbar-fixed-top');

  $(document).scroll(function () {
    scroll_pos = $(this).scrollTop();
    if (scroll_pos > 440) {
      $(".navbar-default").addClass('active');
    } else {
      if ($(".navbar-default").hasClass('home') && $(".navbar-collapse").hasClass('in')) return;
      $(".navbar-default").removeClass('active');
    }
  });

  $('.navbar-toggle').click(function (event) {
    if (!$('.navbar-default').hasClass('active')) {
      $(".navbar-default").addClass('active home');
    } else if ($(".navbar-default").hasClass('home') && scroll_pos < 440) {
      $(".navbar-default").removeClass('active');
    }
  });

  $(window).resize(function () {
    var viewportWidth = window.innerWidth;
    if ($(".navbar-default").hasClass('home') && $('.navbar-collapse').hasClass('in')) {
      if (viewportWidth > 767) {
        $(".navbar-default").removeClass('active');
      } else {
        $(".navbar-default").addClass('active');
      }
    }

  });

}

export function scrollRevelation(classString) {
  ScrollReveal.reveal(classString, { interval: 200 })
}


export function navActivePage() {
  $('nav li a[href=".' + location.pathname + '"]').addClass('active');
  if (location.pathname == '/') $('nav li a[href="./index.html"]').addClass('active')
}


export function maskPhoneNumber() {
  const el = document.getElementById('phone-mask')

  const phone = document.createElement('span')
  phone.innerHTML = '+44 1273 251 321'

  el.addEventListener('click', () => {
    el.parentElement.append(phone)
    el.remove()
  })
}