// Import main SCSS for webpack compilation
import './styles/main.scss'

// Import all JS lib for webpack compilation
import './scripts/lib'

// Import your custom function
import {
  navbarFixedTopAnimation,
  scrollRevelation,
  navActivePage,
  maskPhoneNumber
} from './scripts/main'

// Declare which function are accessible to the browser
Object.assign(window, {
  navbarFixedTopAnimation,
  scrollRevelation,
  navActivePage,
  maskPhoneNumber
})
